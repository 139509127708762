import {
  TRPC_ERROR_CODES_BY_KEY,
  type TRPC_ERROR_CODE_KEY,
} from '@trpc/server/rpc'
import { z } from 'zod'

const TRPC_ERROR_CODE_KEY_ENUM = Object.keys(
  TRPC_ERROR_CODES_BY_KEY,
) as unknown as [TRPC_ERROR_CODE_KEY, ...TRPC_ERROR_CODE_KEY[]]

export const TRPCWithErrorCodeSchema = z
  .object({
    message: z.string().optional(),
    data: z.object({
      code: z.enum(TRPC_ERROR_CODE_KEY_ENUM),
    }),
  })
  .transform((input) => ({
    message: input.message,
    code: input.data.code,
  }))

export const ZodErrorDetailSchema = z
  .string() // Start with a string
  .transform((input) => {
    try {
      return JSON.parse(input) // Parse the JSON string
    } catch (err) {
      return undefined
    }
  })
  .refine((data) => data !== undefined, {
    message: 'Invalid JSON format',
  })
  .pipe(
    z
      .object({
        message: z.string(), // The error message
        code: z.string(), // The error code
        path: z.array(z.string()), // The path to the invalid field
      })
      .array()
      .min(1),
  )
