import { NextRouter } from 'next/router'
import { CALLBACK_URL_KEY } from '~/constants'
import { getRedirectPath } from './url'

// enum containing all the static routes in the application
export enum StaticRoutes {
  ADMIN_TEAM = '/admin/teams',
  ADMIN_USERS = '/admin/users',
  ADMIN_LIST_TEMPLATE = `/admin/list-templates`,
  ADMIN_CREATE_LIST_TEMPLATE = `/admin/list-templates/create`,
  ADMIN_CHECKS = '/admin/checks',
  ADMIN_RESPONSES = '/admin/responses',
  ADMIN_HOME_PAGE = '/admin',
  LISTS_DASHBOARD = '/lists',
  HISTORICAL_CHECKS = '/history',
  SIGN_IN = '/sign-in',
  ISSUES = '/issues',
}

export const routeByWindow = (route: StaticRoutes) => {
  const params = new URLSearchParams(window.location.search)
  const callbackUrl = params.get('callbackUrl')
  window.location.href = callbackUrl
    ? `${route}/?${CALLBACK_URL_KEY}=${getRedirectPath(
        StaticRoutes.LISTS_DASHBOARD,
        callbackUrl,
      )}`
    : route
}

export class RouterNavigation {
  public static toBack = (router: NextRouter) => {
    router.back()
  }
  public static toAdminNewListTemplate = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: StaticRoutes.ADMIN_CREATE_LIST_TEMPLATE,
      query,
    })
  }
  public static toAdminLists = (
    router: NextRouter,
    teamId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/teams/${teamId}/lists`,
      query,
    })
  }
  public static toAdminEditTemplate = (
    router: NextRouter,
    rootListTemplateId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/list-templates/${rootListTemplateId}/edit`,
      query,
    })
  }
  public static toAdminListsUsingTemplate = (
    router: NextRouter,
    rootListTemplateId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/list-templates/${rootListTemplateId}/lists`,
      query,
    })
  }
  public static toAdminTemplatesDashboard = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: StaticRoutes.ADMIN_LIST_TEMPLATE,
      query,
    })
  }
  public static toAdminListTemplate = (
    router: NextRouter,
    templateId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/list-templates/${templateId}`,
      query,
    })
  }
  public static toAdminAssignListTemplate = (
    router: NextRouter,
    templateId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/list-templates/${templateId}/assign`,
      query,
    })
  }
  public static toAdminUpdateList = (
    router: NextRouter,
    listTemplateId: string,
    listId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/list-templates/${listTemplateId}/lists/${listId}`,
      query,
    })
  }
  public static toAdminTeamsDashboard = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: StaticRoutes.ADMIN_TEAM,
      query,
    })
  }
  public static toAdminUsersDashboard = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: StaticRoutes.ADMIN_USERS,
      query,
    })
  }
  public static toAdminChecks = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: StaticRoutes.ADMIN_CHECKS,
      query,
    })
  }
  public static toAdminResponses = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: StaticRoutes.ADMIN_RESPONSES,
      query,
    })
  }
  public static toAdminTeamView = (
    router: NextRouter,
    teamId: string,
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/admin/teams/${teamId}`,

      query,
    })
  }

  public static toSections = (
    router: NextRouter,
    listId: string,
    sections: string[],
    query?: { [key: string]: string },
  ) => {
    router.push({
      pathname: `/lists/${listId}/${sections.join('/')}`,
      query,
    })
  }
  public static toRoot = (
    router: NextRouter,
    listId: string,
    rootListTemplateId: string,
    query?: { [key: string]: string },
  ) => {
    const pathname = `/lists/${listId}/${rootListTemplateId}`
    router.push(
      {
        pathname,
        query: query,
      },
      pathname,
    )
  }

  public static toStatus = (
    router: NextRouter,
    listId: string,
    query?: { [key: string]: string },
  ) => {
    const pathname = `/lists/${listId}/status`
    router.push({ pathname, query }, pathname)
  }

  public static toListSearch = (
    router: NextRouter,
    listId: string,
    query?: { [key: string]: string },
  ) => {
    const pathname = `/lists/${listId}/search`
    router.push({ pathname, query }, pathname)
  }
  public static toHistory = (
    router: NextRouter,
    listId: string,
    query?: { [key: string]: string },
  ) => {
    const pathname = `/lists/${listId}/reports`
    router.push({ pathname, query }, pathname)
  }
  public static toListsDashboard = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    const pathname = StaticRoutes.LISTS_DASHBOARD
    router.push({ pathname, query }, pathname)
  }

  public static toHistoricalChecks = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    const pathname = StaticRoutes.HISTORICAL_CHECKS
    router.push({ pathname, query }, pathname)
  }

  public static toSignIn = (
    router: NextRouter,
    query?: { [key: string]: string },
  ) => {
    const pathname = StaticRoutes.SIGN_IN
    router.push({ pathname, query }, pathname)
  }

  public static toSkipCheck = (
    router: NextRouter,
    listId: string,
    query?: { [key: string]: string },
  ) => {
    const pathname = `/lists/${listId}/skip-check`
    router.push({ pathname, query }, pathname)
  }
}
